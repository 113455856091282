var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"provinces"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"اسم المنطقة","placeholder":"اضافة منطقة جديدة","outlined":"","dense":""},model:{value:(_vm.addProvince.title),callback:function ($$v) {_vm.$set(_vm.addProvince, "title", $$v)},expression:"addProvince.title"}})],1),_c('v-col',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addNewProvince()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.provinces.header,"items":_vm.provinces.data},scopedSlots:_vm._u([{key:"item.visitCauseName",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","value":item.provinceName,"hide-details":""},on:{"change":function($event){return _vm.editProvince(item.idProvince, $event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red","title":"حذف المنطقة"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteProvince(item.idProvince)}}},[_c('v-list-item-title',[_vm._v("اضغط هنا لتأكيد حذف المنطقة")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }