var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.forceRerender,staticClass:"pa-5",attrs:{"id":"permissionsPage"}},[(
      _vm.rolePermissions.length > 0 && _vm.permissions.length > 0 && _vm.roles.length > 0
    )?_c('v-row',[_c('v-divider',{attrs:{"vertical":""}}),_vm._l((_vm.roles.filter(
        function (role) { return role.idRole == 1 ||
          role.idRole == 2 ||
          role.idRole == 6 ||
          role.idRole == 7; }
      )),function(role){return [_c('v-col',{key:role.idRole},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-alert',{attrs:{"type":"info"}},[_vm._v(_vm._s(role.roleName))]),_vm._l((_vm.permissionGroup),function(group){return [_c('v-alert',{key:group.groupName,attrs:{"type":"success"}},[_vm._v(_vm._s(group.groupName))]),_vm._l((_vm.permissions.filter(function (e) { return group.permissions.includes(e.idPermission); }
              )),function(permission){return _c('v-checkbox',{key:permission.idPermission,attrs:{"value":_vm.getPermissions(role.idRole, permission.idPermission),"input-value":_vm.getPermissions(role.idRole, permission.idPermission),"label":permission.permissionName},on:{"change":function($event){return _vm.changePerm(role.idRole, permission.idPermission, $event)}}})})]})],2)],1),_c('v-divider',{key:'ROLE_' + role.idRole,attrs:{"vertical":""}})]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }