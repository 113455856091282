var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"CustomersCalsses"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"اسم الفئة","placeholder":"اضافة فئة جديدة","outlined":"","dense":""},model:{value:(_vm.customerClassFields.title),callback:function ($$v) {_vm.$set(_vm.customerClassFields, "title", $$v)},expression:"customerClassFields.title"}})],1),_c('v-col',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addNewCustomerClass()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.customerClasses.header,"items":_vm.customerClasses.data},scopedSlots:_vm._u([{key:"item.customerClassName",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","value":item.customerClassName,"hide-details":""},on:{"change":function($event){return _vm.editGroup(item.idCustomerClass, $event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red","title":"حذف الفئة"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteCustomerClass(item.idCustomerClass)}}},[_c('v-list-item-title',[_vm._v("اضغط هنا لتأكيد حذف الفئة")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }