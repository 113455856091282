var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"supervisorDelegates"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list',_vm._l((_vm.supervisors),function(supervisor,i){return _c('v-list-item',{key:i,class:_vm.selectedSuperVisor == supervisor.idUser
              ? 'v-list-item--active'
              : '',on:{"click":function($event){return _vm.setSelectedSuperVisor(supervisor.idUser)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(supervisor.username)}})],1)],1)}),1)],1),(_vm.selectedSuperVisor > 0)?_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.delegates,"item-text":"username","item-value":"idUser","outlined":"","multiple":"","dense":"","label":"اختيار مندوب"},model:{value:(_vm.selectedDelegate),callback:function ($$v) {_vm.selectedDelegate=$$v},expression:"selectedDelegate"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addDeleteSuperVisor()}}},[_vm._v("اضافة")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.supervisorDelegates,"headers":_vm.tableHeaders,"height":"500","fixed-header":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red","title":"حذف المندوب من قائمة المشرف"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteDelegate(item.idSupervisorDelegates)}}},[_c('v-list-item-title',[_vm._v("اضغط هنا لتأكيد حذف المندوب من قائمة المشرف")])],1)],1)],1)]}}],null,true)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }